import React from 'react';
import Paragraph from '../Geral/Paragraph';
import TitleVazado from '../Geral/TitleVazado';
import styles from '../AboutUs/AboutUs.module.css';

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <TitleVazado></TitleVazado>
      <Paragraph style={{ color: 'white' }}>
        Nós somos um Clube de Cheerleading Allstar na cidade de São Paulo,
        dirigido por Tiago Bento, Guilherme Mantovan, Alice Abade e Mariane
        Mendes.
      </Paragraph>
      <Paragraph style={{ color: 'white' }}>
        Nós somos um ginásio acolhedor e que, desde sua criação, zela pelo
        companheirismo, respeito e disciplina. Esperamos que você mantenha sua
        determinação e garra e que faça boas amizades, mas acima de tudo que
        desenvolva sua técnica e melhore seu desempenho. Todo treino é uma nova
        oportunidade para ser melhor e evoluir do treino anterior.
      </Paragraph>
    </div>
  );
};

export default AboutUs;
