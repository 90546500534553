import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Nav.module.css';
import Logo from './Logo';

const Nav = () => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const updateMedia = () => {
      setDesktop(window.innerWidth > 992);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div>
        <div className={styles.divD}></div>
        {!isDesktop && (
          <button
            ref={buttonRef}
            className={`${styles.hamburger} ${isMenuOpen ? styles.active : ''}`}
            onClick={toggleMenu}
          >
            ☰
          </button>
        )}
        <nav
          ref={menuRef}
          className={`${styles.nav} ${isMenuOpen ? styles.show : ''}`}
        >
          <div className={styles.divNavList}>
            <ul className={styles.mainNav}>
              <li>
                <Link
                  className={`${styles.link} ${
                    location.pathname === '/' ? styles.activeLink : ''
                  }`}
                  to="/"
                  aria-label="Home"
                >
                  Início
                </Link>
              </li>
              <li className={styles.setaNav}>
                <Link className={styles.link} to="/times">
                  All Star Cheerleading
                </Link>
                <ul className={styles.subNav}>
                  <li>
                    <Link
                      className={`${styles.link} ${
                        location.pathname === '/cheerleading'
                          ? styles.activeLink
                          : ''
                      }`}
                      to="/cheerleading"
                      aria-label="Cheerleading"
                    >
                      O que é Cheerleading ?
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${styles.link} ${
                        location.pathname === '/historia'
                          ? styles.activeLink
                          : ''
                      }`}
                      to="/historia"
                      aria-label="Historia"
                    >
                      Nossa História
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${styles.link} ${
                        location.pathname === '/espaco' ? styles.activeLink : ''
                      }`}
                      to="/espaco"
                      aria-label="Nosso Espaco"
                    >
                      Nosso Espaço
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${styles.link} ${
                        location.pathname === '/equipes-2024'
                          ? styles.activeLink
                          : ''
                      }`}
                      to="/equipes-2024"
                      aria-label="2024 - equipes"
                    >
                      2024 - Equipes
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className={`${styles.link} ${
                    location.pathname === '/staff' ? styles.activeLink : ''
                  }`}
                  to="/staff"
                  aria-label="Staff"
                >
                  Staff
                </Link>
              </li>
              <li className={styles.setaNav}>
                <Link
                  className={`${styles.link} ${
                    location.pathname === '/programas' ? styles.activeLink : ''
                  }`}
                  to="/programas"
                  aria-label="Programas"
                >
                  Programas
                </Link>
                <ul className={styles.subNav}>
                  <li>
                    <Link
                      className={`${styles.link} ${
                        location.pathname === '/arenas' ? styles.activeLink : ''
                      }`}
                      to="/arenas"
                      aria-label="arenas"
                    >
                      Arenas
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${styles.link} ${
                        location.pathname === '/aulas-privadas'
                          ? styles.activeLink
                          : ''
                      }`}
                      to="/aulas-privadas"
                      aria-label="Aulas Privadas"
                    >
                      Aulas Privadas
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className={`${styles.link} ${
                    location.pathname === '/eventos' ? styles.activeLink : ''
                  }`}
                  to="/eventos"
                  aria-label="Eventos"
                >
                  Eventos
                </Link>
              </li>
              <li>
                <Link
                  className={`${styles.link} ${
                    location.pathname === '/location' ? styles.activeLink : ''
                  }`}
                  to="/location"
                  aria-label="location"
                >
                  Localização
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
