import React from 'react';
import Teams from '../Data/TeamsData';
import styles from './TeamsList.module.css';
import TitleVazado from '../Geral/TitleVazado';
import DivContent from '../Geral/DivContent';

const TeamsList = () => {
  return (
    <DivContent>
      <TitleVazado>Equipes</TitleVazado>
      <ul className={styles.list}>
        {Teams.map((team, index) => (
          <li key={index} className={styles.teamsList}>
            <img src={team.img} alt={`Logo do time ${team.name}`} />
            <div className={styles.teamInfo}>
              <p>{team.name}</p>
              <p>{team.level}</p>
            </div>
          </li>
        ))}
      </ul>
    </DivContent>
  );
};

export default TeamsList;
