import React from 'react';
// import styles from './Historia.module.css';
import TitleVazado from '../Geral/TitleVazado';
import DivContent from '../Geral/DivContent';
import ParagraphWhite from '../Geral/ParagraphWhite';

const Historia = () => {
  return (
    <DivContent>
      <TitleVazado>História</TitleVazado>
      <ParagraphWhite>Página em Construção.</ParagraphWhite>
    </DivContent>
  );
};

export default Historia;
