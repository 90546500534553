import React from 'react';
import Title from '../../Geral/Title';
import Paragraph from '../../Geral/Paragraph';

const Email = () => {
  return (
    <>
      <div>
        <Title>Email</Title>
        <Paragraph style={{ color: 'white' }}>
          gym.spiritoftitans@gmail.com
        </Paragraph>
      </div>
    </>
  );
};

export default Email;
