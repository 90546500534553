import React from 'react';
import TitleVazado from '../Geral/TitleVazado';
import ParagraphWhite from '../Geral/ParagraphWhite';
import DivContent from '../Geral/DivContent';

const Cheerleading = () => {
  return (
    <DivContent>
      <TitleVazado>O que é Cheerleading ?</TitleVazado>
      <ParagraphWhite>
        O Cheerleading nasceu na Inglaterra e foi transportado para os Estados
        Unidos. Inicialmente, era praticado apenas por homens, mas por volta dos
        anos 1920, as mulheres começaram a participar. Com o passar dos anos, o
        Cheerleading foi se tornando uma atividade cada vez mais popular, até se
        consolidar como um esporte em si. Em 2016, foi finalmente reconhecido
        como um esporte pelo Comitê Olímpico Internacional.
      </ParagraphWhite>

      <ParagraphWhite>
        O Cheerleading é um esporte coletivo que envolve uma performance de um
        grupo de pessoas com habilidades atléticas, incluindo Jumps (saltos),
        Tumbling (elementos da ginástica que exigem consciência, coordenação e
        coragem), Stunt (formações com 2 a 4 bases e 1 flyer) e Dance (dança).
      </ParagraphWhite>

      <ParagraphWhite>
        No Brasil, o Cheerleading está em ascensão. É comum ver equipes
        universitárias representando suas atléticas. Essas equipes têm vínculo
        com alguma universidade ou curso específico, ou seja, para participar de
        uma equipe universitária, é necessário estar matriculado na instituição
        de ensino correspondente.
      </ParagraphWhite>

      <ParagraphWhite>
        Por outro lado, as equipes All Star não possuem ligação com nenhuma
        entidade educacional específica, permitindo a participação de qualquer
        pessoa interessada. Ginásios All Star, como o Spirit Of Titans, possuem
        equipes em diversos níveis e categorias, proporcionando oportunidades
        para os atletas evoluírem no esporte e competirem nos principais
        campeonatos ao longo da temporada.
      </ParagraphWhite>

      <ParagraphWhite>
        Os campeonatos de Cheerleading são divididos em várias categorias e
        níveis, incluindo divisões Universitárias e All Star. Os níveis variam
        de 1 a 7, com subcategorias como tumbling (com elementos de acrobacia) e
        non-tumbling (sem elementos de acrobacia). As equipes podem ser COED
        (mistas, com homens e mulheres), ALL GIRL (somente mulheres) e ALL BOY
        (somente homens). Além disso, há competições nas categorias individuais,
        onde atletas competem sozinhos em suas especialidades.
      </ParagraphWhite>

      <ParagraphWhite>
        Esses campeonatos são realizados em diversas partes do mundo, e o Brasil
        não fica de fora, participando de competições nacionais e
        internacionais. O crescimento do esporte no país é visível, com um
        aumento significativo no número de praticantes e de ginásios dedicados
        ao Cheerleading. A evolução do esporte no Brasil aponta para um futuro
        promissor, com cada vez mais pessoas se apaixonando por essa modalidade
        que combina atletismo, dança e muita energia.
      </ParagraphWhite>
    </DivContent>
  );
};

export default Cheerleading;
