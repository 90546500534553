import React from 'react';
// import styles from './Espaco.module.css';
import TitleVazado from '../Geral/TitleVazado';
import DivContent from '../Geral/DivContent';
import ParagraphWhite from '../Geral/ParagraphWhite';

const Espaco = () => {
  return (
    <DivContent>
      <TitleVazado>Espaço</TitleVazado>
      <ParagraphWhite>Página em Construção.</ParagraphWhite>
    </DivContent>
  );
};

export default Espaco;
