import React from 'react';
// import styles from './Footer.module.css';
import Location from './components/Location';
import Whatsapp from './components/Whatsapp';
import Email from './components/Email';
import styles from './Container.module.css';
import SocialMedia from './components/SocialMedia';

const Contacts = () => {
  return (
    <>
      <ul className={styles.listaFooter}>
        <li>
          <SocialMedia />
        </li>
        <li>
          <Location />
        </li>
        <li>
          <Whatsapp />
        </li>
        <li>
          <Email />
        </li>
      </ul>
    </>
  );
};

export default Contacts;
