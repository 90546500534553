import React from 'react';
import Copyright from './Copyright';
import Container from './Container';
import styles from './Footer.module.css';
import Maps from './Map';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.loc}>
        <div className={styles.divMap}>
          <Maps />
        </div>
        <Container />
      </div>
      <Copyright />
    </footer>
  );
};

export default Footer;
