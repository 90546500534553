import React from 'react';
import styles from './Banner.module.css';

const Banner = ({ imageUrl, altText }) => {
  return (
    <div>
      <img src={imageUrl} alt={altText} className={styles.banner} />
    </div>
  );
};

export default Banner;
