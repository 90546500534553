import React from 'react';
import styles from './AulasPrivadas.module.css';
import TitleVazado from '../Geral/TitleVazado';
import ParagraphWhite from '../Geral/ParagraphWhite';
import Whatsapp from '../footer/components/Whatsapp';
import DivContent from '../Geral/DivContent';

const AulasPrivadas = () => {
  return (
    <DivContent>
      <TitleVazado>Aulas Privadas</TitleVazado>
      <ParagraphWhite>
        Se você está interessado em aprimorar suas habilidades no cheerleading
        de forma personalizada e exclusiva, as aulas privadas do Spirit Of
        Titans são a escolha perfeita para você. Nossas aulas privadas são
        projetadas para atender às necessidades individuais de cada atleta,
        oferecendo treinamento intensivo e específico em todas as áreas do
        cheerleading.
        <br />
        <br />
        <span className={styles.span}>Benefícios das Aulas Privadas:</span>
        <br />
        <ul>
          <li>
            - <span className={styles.span}>Atenção Personalizada:</span> Receba
            atenção individualizada de nossos treinadores experientes, que irão
            focar nas suas necessidades específicas e ajudar a alcançar seus
            objetivos.
          </li>
          <li>
            - <span className={styles.span}>Aprimoramento de Habilidades:</span>{' '}
            Melhore suas técnicas de tumbling, stunts, jumps e dança com
            treinamentos intensivos e direcionados.
          </li>
          <li>
            - <span className={styles.span}>Flexibilidade de Horários:</span>{' '}
            Escolha horários que se ajustem à sua agenda, proporcionando maior
            conveniência e facilidade.
          </li>
          <li>
            - <span className={styles.span}>Progressão Acelerada:</span> Avance
            rapidamente no esporte com um programa de treinamento personalizado
            que se adapta ao seu ritmo e nível de habilidade.
          </li>
        </ul>
        <br />
        Para agendar suas aulas privadas, entre em contato conosco pelo
        WhatsApp. Nossos treinadores estão ansiosos para ajudar você a alcançar
        seu máximo potencial no cheerleading!
        <br />
        <Whatsapp></Whatsapp>
        Aguardamos o seu contato para iniciar essa jornada de desenvolvimento e
        sucesso juntos!
      </ParagraphWhite>
    </DivContent>
  );
};

export default AulasPrivadas;
