import React from 'react';
import Table from './Table';
import { Arenas, ArenasMobile } from '../Data/ArenasData';
import styles from './Calendar.module.css';
import Infos from './Infos';
import Button from '../Geral/Button';
import ArenasBanner from './ArenasBanner';
import TitleVazado from '../Geral/TitleVazado';

const Calendar = () => {
  return (
    <div>
      <TitleVazado>Arenas - Agosto</TitleVazado>
      <Table Arenas={Arenas} ArenasMobile={ArenasMobile} />
      <Infos />
      <ArenasBanner />
      <div className={styles.arenasButton}>
        <Button link="https://venda.nextfit.com.br/5fd5636b-9e06-4cd3-9766-eddfaba724c8/contratos">
          Compre seu pacote de Arenas
        </Button>
      </div>
    </div>
  );
};

export default Calendar;
