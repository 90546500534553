import React from 'react';
import Paragraph from '../Geral/Paragraph';
import Title from '../Geral/Title';
import styles from './Infos.module.css';

const Infos = () => {
  return (
    <div>
      <div className={styles.divInfos}>
        <Title>Tumbling A</Title>
        <Paragraph style={{ color: 'white' }}>
          Iniciação/básico Estrela, rodante e reversões. Serão desenvolvidos
          elementos de iniciação ao tumbling e acrobacias, desenvolvimento
          corporal de força e flexibilidade e consciência corporal. Ideal para
          quem está começando com o tumbling ou precisa de mais atenção aos
          elementos iniciais/básicos.
        </Paragraph>
        <Title>Tumbling B </Title>
        <Paragraph style={{ color: 'white' }}>
          Intermediário/avançado Desenvolvimento de elementos de maior
          complexidade e necessidade de maior controle corporal. Serão
          desenvolvidos elementos como rodante+mortal (grupado/estendido),
          rodante+flic, tempo, estrelas sem mãos e etc. O mínimo esperado para
          bom aproveitamento da Arena é um rodante com boa impulsão/repulsão.
          Sujeito à disponibilidade de agendamento/lotação.
        </Paragraph>
      </div>
    </div>
  );
};

export default Infos;
