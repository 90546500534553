const Arenas = [
  [
    '',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado',
  ],
  ['09h30 - 10h30', '-', '-', '-', '-', '-', 'Partner'],
  ['19h30 - 20h30', 'Partner', '-', 'Tumbling A', 'Tumbling A', 'Stunt', '-'],
  ['20h30 - 21h30', '-', '-', 'Tumbling B', 'Tumbling B', '-', '-'],
];

const ArenasMobile = [
  ['-', '09h30 - 10h30', '19h30 - 20h30', '20h30 - 21h30'],
  ['Segunda-Feira', '-', 'Partner', '-'],
  ['Terça-Feira', '-', '-', '-'],
  ['Quarta-Feira', '-', 'Tumbling A', 'Tumbling B'],
  ['Quinta-Feira', '-', 'Tumbling A', 'Tumbling B'],
  ['Sexta-Feira', '-', 'Stunt', '-'],
  ['Sábado', 'Partner', '-', '-'],
];
export { Arenas, ArenasMobile };
