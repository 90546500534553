import React from 'react';
import styles from '../Footer.module.css';
import Title from '../../Geral/Title';

const Whatsapp = () => {
  return (
    <>
      <div>
        <Title>WhatsApp</Title>
        <p>
          <a
            href="https://wa.me/5511993576865"
            target="_blank"
            rel="noreferrer"
            className={styles.whatsLink}
          >
            Entre em contato conosco.
          </a>
        </p>
      </div>
    </>
  );
};

export default Whatsapp;
