import React from 'react';
import styles from './ParagraphWhite.module.css';

const ParagraphWhite = ({ children, ...props }) => {
  return (
    <>
      <p {...props} className={styles.p}>
        {children}
      </p>
    </>
  );
};

export default ParagraphWhite;
