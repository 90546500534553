import React from 'react';
import styles from './Title.module.css';

const Title = ({ children, ...props }) => {
  return (
    <>
      <h1 {...props} className={styles.h1}>
        {children}
      </h1>
    </>
  );
};

export default Title;
