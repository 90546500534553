import theia from '../Assets/images/theia.png';
import oceanus from '../Assets/images/oceanus.png';
import ceos from '../Assets/images/ceos.png';
import perses from '../Assets/images/perses.png';
import atlas from '../Assets/images/atlas.png';
import selene from '../Assets/images/selene.png';
import hyperion from '../Assets/images/hyperion.png';
import cronos from '../Assets/images/cronos.png';
import gaia from '../Assets/images/gaia.png';

const TeamsData = [
  {
    name: 'Theia',
    level: 'All Girl - NV 2 NT',
    img: theia,
    coach: 'Mary',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    name: 'Oceanus',
    level: 'COED - NV 2',
    img: oceanus,
    coach: 'Jéssica',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Ceos',
    level: 'COED - NV 2 NT',
    img: ceos,
    coach: 'Jéssica',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Selene',
    level: 'All Girl - NV 3',
    img: selene,
    coach: 'Alice',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Hyperion',
    level: 'COED - NV 3',
    img: hyperion,
    coach: 'Guilherme',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Perses',
    level: 'COED - NV 3 NT',
    img: perses,
    coach: 'Guilherme',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Cronos',
    level: 'COED - NV 4',
    img: cronos,
    coach: 'Tiago',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Atlas',
    level: 'All Boy - NV 3',
    img: atlas,
    coach: 'Tiago',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    name: 'Gaia',
    level: 'Dance',
    img: gaia,
    coach: 'Tiago',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

export default TeamsData;
