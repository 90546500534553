import React from 'react';
// import styles from './Staff.module.css';
import TitleVazado from '../Geral/TitleVazado';

const Staff = () => {
  return (
    <div>
      <TitleVazado>Coachs</TitleVazado>
      <p>Página em Construção.</p>
      <TitleVazado>Marketing</TitleVazado>
      <p>Página em Construção.</p>
    </div>
  );
};

export default Staff;
