import React from 'react';
import styles from './Button.module.css';

const Button = ({ children, link }) => {
  const handleClick = () => {
    window.open(link, '_blank');
  };

  return (
    <button className={styles.button} onClick={handleClick}>
      {children}
    </button>
  );
};

export default Button;
