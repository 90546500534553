import React from 'react';
import styles from './TitleVazado.module.css';

const TitleVazado = ({ children, ...props }) => {
  return (
    <>
      <h1 {...props} className={styles.titleVazado}>
        {children}
        <span></span>
      </h1>
    </>
  );
};

export default TitleVazado;
