import React, { useState, useEffect } from 'react';
import styles from './Table.module.css';

const Table = ({ Arenas, ArenasMobile }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 999);

  useEffect(() => {
    const updateMedia = () => {
      setDesktop(window.innerWidth > 991);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const headers = Arenas.length > 0 ? Object.keys(Arenas[0]) : [];
  // const headersMobile = ArenasMobile.length > 0 ? ArenasMobile[0].slice(1) : [];

  return (
    <div>
      {!isDesktop ? (
        <table className={styles.table_container}>
          <tbody>
            {ArenasMobile.slice(0).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className={styles.table_container}>
          <tbody>
            {Arenas.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, cellIndex) => (
                  <td key={cellIndex}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Table;
