import React from 'react';
import Title from '../../Geral/Title';
import Paragraph from '../../Geral/Paragraph';

const Location = () => {
  return (
    <>
      <div>
        <Title>Localização</Title>
        <Paragraph style={{ color: 'white' }}>
          R. Caetano de Oliveira, 46B - Jardim da Gloria, {'  '}
          São Paulo - SP, {'  '}
          01545-050, Brazil
        </Paragraph>
      </div>
    </>
  );
};

export default Location;
