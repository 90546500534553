import React, { useEffect, useRef } from 'react';
import icon from '../Assets/icons/icon-map2.png';
import mapboxgl from 'mapbox-gl';
import './Map.css';

const Map = () => {
  const TOKEN =
    'pk.eyJ1IjoibW1lbmRlczExMDQiLCJhIjoiY2x2OXV3aWFuMTBzYTJqbnl1aWgzamxxaCJ9.ollmgnlkVX-uT2uccSeBjQ';
  const LATITUDE = -23.5809551;
  const LONGITUDE = -46.6164878;

  const mapContainer = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = TOKEN;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [LONGITUDE, LATITUDE],
      zoom: 15,
    });

    map.on('load', () => {
      // Add an image to use as a custom marker
      map.loadImage(icon, (error, image) => {
        if (error) throw error;
        map.addImage('custom-marker', image);
        map.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [LONGITUDE, LATITUDE],
                },
                properties: {
                  title: 'SPIRIT OF TITANS',
                },
              },
            ],
          },
        });

        // Add a symbol layer
        map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'points',
          layout: {
            'icon-image': 'custom-marker',
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.25],
            'text-anchor': 'top',
          },
        });
      });
    });

    return () => {
      map.remove(); // Limpa o mapa quando o componente é desmontado
    };
  }, [LONGITUDE, LATITUDE]); // Adiciona um array vazio para garantir que o useEffect seja executado apenas uma vez

  return (
    <div
      ref={mapContainer}
      style={{
        width: 'auto',
        height: '500px',
        minHeight: '300px',
        maxHeight: '500px',
      }}
    ></div>
  );
};

export default Map;
