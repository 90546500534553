import React from 'react';
// import styles from './Eventos.module.css';
import TitleVazado from '../Geral/TitleVazado';
import ParagraphWhite from '../Geral/ParagraphWhite';
import Span from '../Geral/Span';
import DivContent from '../Geral/DivContent';

const Eventos = () => {
  return (
    <DivContent>
      <TitleVazado>Eventos</TitleVazado>
      <ParagraphWhite>
        No momento, não temos eventos programados dentro do ginásio do Spirit Of
        Titans. No entanto, estamos empolgados em anunciar nossa participação em
        diversos campeonatos nos próximos meses. Confira as datas dos
        campeonatos em que estaremos competindo:
        <br />
        <ul>
          <li>
            - <Span>Campeonato Arena Cheer </Span>
            <br />
            - Data: 22 de Setembro de 2024
            <br />
            - Local: -
            <br />
          </li>
          <br />
          <li>
            - <Span>Campeonato CheerMagic</Span>
            <br />
            - Data: 12 de Outubro de 2024
            <br />
            - Local: -
            <br />
          </li>
          <br />
          <li>
            - <Span>Campeonato Cheerfest</Span>
            <br />
            - Data: 06 e 07 de Dezembro de 2024
            <br />
            - Local: -
            <br />
          </li>
        </ul>
        <br />
        Acompanhe nossas redes sociais para mais informações e atualizações
        sobre nossos eventos e campeonatos. Esperamos ver todos vocês torcendo
        por nós!
      </ParagraphWhite>
    </DivContent>
  );
};

export default Eventos;
