import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { scroller } from 'react-scroll';
import './App.css';
import Calendar from './components/Arenas/Calendar';
import Banner from './components/Geral/Banner';
import BannerDesktop from './components/Assets/images/titansBanner2.gif';
import BannerMobile from './components/Assets/images/titansBannerMobile.gif';
import Footer from './components/footer/Footer';
import Header from './components/header/Nav';
import TeamsList from './components/pages/TeamsList';
import Arenas from './components/pages/Arenas';
import AboutUs from './components/AboutUs/AboutUs';
import Newsletter from './components/Newsletter/Newsletter';
import Cheerleading from './components/pages/Cheerleading';
import Historia from './components/pages/Historia';
import Espaco from './components/pages/Espaco';
import Equipes from './components/pages/TeamsList';
import Staff from './components/pages/Staff';
import Programas from './components/pages/Eventos';
import AulasPrivadas from './components/pages/AulasPrivadas';
import Eventos from './components/pages/Eventos';
import Location from './components/pages/AulasPrivadas';

function App() {
  const home = useRef(null);
  const teamsList = useRef(null);
  const arenas = useRef(null);
  const footer = useRef(null);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);

  const scrollToSection = (ref) => {
    scroller.scrollTo(ref.current.id, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  useEffect(() => {
    const updateMedia = () => {
      setDesktop(window.innerWidth > 992);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {!isDesktop ? (
                    <Banner imageUrl={BannerMobile} altText="Banner Titans" />
                  ) : (
                    <Banner imageUrl={BannerDesktop} altText="Banner Titans" />
                  )}
                  <AboutUs ref={home} />
                  <TeamsList ref={teamsList} />
                  <Calendar ref={arenas} />
                  <Newsletter />
                </>
              }
            />
            <Route path="/cheerleading" element={<Cheerleading />} />
            <Route path="/historia" element={<Historia />} />
            <Route path="/espaco" element={<Espaco />} />
            <Route path="/equipes-2024" element={<Equipes />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/programas" element={<Programas />} />
            <Route path="/arenas" element={<Arenas />} />
            <Route path="/aulas-privadas" element={<AulasPrivadas />} />
            <Route path="/eventos" element={<Eventos />} />
            <Route path="/location" element={<Location />} />
          </Routes>
          <Footer ref={footer} />
        </main>
        <ScrollToSection
          scrollToSection={scrollToSection}
          refs={{
            home,
            teamsList,
            footer,
          }}
        />
      </div>
    </Router>
  );
}

function ScrollToSection({ refs }) {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.substring(1);
    if (refs[sectionId]) {
      scroller.scrollTo(refs[sectionId].current.id, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location, refs]);
}

export default App;
