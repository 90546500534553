import React from 'react';
import Calendar from '../Arenas/Calendar';
// import DivContent from '../Geral/DivContent';

const Arenas = () => {
  return (
    <div>
      <Calendar />
    </div>
  );
};

export default Arenas;
