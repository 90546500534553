import React, { useState, useEffect } from 'react';
import Banner from '../Geral/Banner';
import BannerMobile from '../Assets/images/arenaMobile.png';
import BannerDesktop from '../Assets/images/ArenaGratis.png';

const ArenasFree = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);

  useEffect(() => {
    const updateMedia = () => {
      setDesktop(window.innerWidth > 992);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <>
      <a href="https://agendamento.nextfit.com.br/d9d4cc87-1268-4ded-9002-7463328dc5b8">
        {!isDesktop ? (
          <Banner imageUrl={BannerMobile} altText="Banner Arena Cortesia" />
        ) : (
          <Banner imageUrl={BannerDesktop} altText="Banner Arena Cortesia" />
        )}
      </a>
    </>
  );
};

export default ArenasFree;
