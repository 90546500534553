import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import styles from './Newsletter.module.css';
import Title from '../Geral/Title';

const Newsletter = ({ status, message, onValidated }) => {
  let email;
  const submit = () => {
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <>
      <div className={styles.newsletter}>
        <Title style={{ color: 'white', fontSize: '1rem' }}>
          Fique por dentro das nossas novidades!
        </Title>
        <input
          ref={(node) => (email = node)}
          type="email"
          placeholder="Seu email"
          className={styles.inputNews}
        />
        <button onClick={submit} className={styles.buttonNews}>
          Quero me inscrever!
        </button>
        {status === 'sending' && (
          <div style={{ color: 'blue' }}>enviando...</div>
        )}
        {status === 'error' && (
          <div
            style={{ color: 'red' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === 'success' && (
          <div style={{ color: 'green' }}>Inscrito!</div>
        )}
      </div>
    </>
  );
};

const url =
  'https://gmail.us22.list-manage.com/subscribe/post?u=eeb8c537e335eb050c06c5b44&id=485a5063bc&f_id=00e2c4e1f0';

const NewsletterForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <Newsletter
        status={status}
        message={message}
        onValidated={(formData) => subscribe(formData)}
      />
    )}
  />
);

export default NewsletterForm;
