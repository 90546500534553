import React from 'react';
import styles from './SocialMedia.module.css';
import Title from '../../Geral/Title';
import instagramIcon from '../../Assets/icons/icon-instagram.png';
import facebookIcon from '../../Assets/icons/icon-facebook.png';
import tiktokIcon from '../../Assets/icons/icon-tiktok.png';

const SocialMedia = () => {
  return (
    <>
      <div>
        <Title>Nossas Redes Sociais</Title>
        <ul className={styles.SocialMediaList}>
          <li>
            <a
              href="https://www.instagram.com/spirit_oftitans"
              target="_blank"
              rel="noreferrer"
              className={styles.rsLink}
            >
              <img
                src={instagramIcon}
                alt="instagram-icon"
                className={styles.Icons}
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/SpiritofTitans"
              target="_blank"
              rel="noreferrer"
              className={styles.rsLink}
            >
              <img
                src={facebookIcon}
                alt="facebook-icon"
                className={styles.Icons}
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@spiritoftitans"
              target="_blank"
              rel="noreferrer"
              className={styles.rsLink}
            >
              <img
                src={tiktokIcon}
                alt="tiktok-icon"
                className={styles.Icons}
              />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SocialMedia;
