import React from 'react';
import styles from './Logo.module.css';
import logoImage from '../Assets/images/logo_SOT.png';

const Logo = () => {
  return (
    <>
      <div className={styles.divD}>
        <img src={logoImage} alt="Logo-Titas" className={styles.logoImg} />
      </div>
    </>
  );
};

export default Logo;
