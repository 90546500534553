import React from 'react';
import Paragraph from '../Geral/Paragraph';
// import styles from './Footer.module.css';

const Copyright = () => {
  return (
    <>
      <Paragraph style={{ color: 'white' }}>
        © 2024 - Todos os direitos reservados. Spirit of Titans. by Mariane
        Mendes
      </Paragraph>
    </>
  );
};

export default Copyright;
